import React from "react"

import Layout from "../components/layout-fr"
import SEO from "../components/seo"
import "@fortawesome/fontawesome-svg-core/styles.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Présentation" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Camping Silveira</h1>
          <h2 class="subtitle">
            Bienvenue aux amateurs de calme et de nature!
          </h2>
        </div>
      </div>
    </section>
    <section class="hero has-background-info">
      <div class="hero-body">
        <div class="container">
          <h2 class="subtitle has-text-white has-text-weight-bold">
            Ouverture du 1 Mai au 15 septembre
          </h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column is-6">
          <div class="content">
            <p>
              Entre mer et montagne, à 100 km de Lisbonne et à 3 km d’Alcobaça,
              Silveira est un camping sans animation, sans piscine ni locatif,
              situé sur une ancienne exploitation agricole de 8 hectares.{" "}
            </p>
            <p>
              Le camping a été aménagé dans sa partie boisée (principalement des
              eucalyptus et des chênes-lièges). Les 19 alvéoles-clairières sont
              séparées les unes des autres par la végétation naturelle. Notre
              capacité maximum est de 60 personnes et nous sommes ouverts du 1er
              mai au 15 septembre.{" "}
            </p>
            <p>A la disposition des campeurs grands et petits:</p>
            <ul type="1">
              <li>Machine à laver</li>
              <li>Barbecues</li>
              <li>Coin repas couvert</li>
              <li>Réfrigérateur et congélateur</li>
              <li>Ping-pong</li>
              <li>Terrain de badminton</li>
              <li>Balançoires</li>
              <li>Une petite bibliothèque et quelques jeux</li>
              <li>
                <strong>
                  Sept hectares de nature où vous pourrez vou promener et vos enfants jouer
                </strong>
              </li>
              <li>Point d'accès Wi-Fi gratuit</li>
            </ul>
          </div>
        </div>
        <div class="column">
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/convivio_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Bloc sanitaire et salle commune
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/eg_plot_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Une alvéole
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/nazare_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Plage de Nazaré (à 15km du camping)
            </p>
          </figure>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
